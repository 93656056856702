.outer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
}
.mapContainer {
  padding-right: 25rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    
  }
.mapContainer > svg {
    width: 100%;
    height: auto;
    
  }

.maptitle {
  font-size: 5rem;
  font-weight: 600;
  margin-bottom: 0rem;
  text-align: center;
  margin-top: 2rem;
}
.country {
  stroke: #333; /* Set the stroke color for countries */
  stroke-width: 0.2; /* Adjust the stroke width */
}

.country:hover {
  fill: #ff9f1c; /* Change the fill color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}
.numberSales {
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  fill: white;
  font-weight: 600;
}