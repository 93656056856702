/* Define CSS variables if you want */
:root {
  --bg-color-light: #ffffff;
  --text-color-light: #000000;
  --bg-color-dark: #343a40;
  --text-color-dark: #ffffff;
}

/* Global light mode styling */
.light {
  background-color: var(--bg-color-light);
  color: var(--text-color-light);
}

/* Global dark mode styling */
.dark {
  background-color: var(--bg-color-dark);
  color: var(--text-color-dark);
}
.light body,
.light td,
.light label,
.light input,
.light select,
.light textarea {
  background-color: var(--bg-color-light);
  color: var(--text-color-light);
}

.light p,
.light h1,
.light h2,
.light h3,
.light h4,
.light h5,
.light h6,
.light span,
.light th,
.light a,
.light button {
  color: var(--text-color-light);
}

.dark body,
.dark td,
.dark label,
.dark input,
.dark select,
.dark textarea {
  background-color: var(--bg-color-dark);
  color: var(--text-color-dark);
}

.dark p,
.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6,
.dark span,
.dark th,
.dark a,
.dark button {
  color: var(--text-color-dark);
}

.dark th {
}

.light th {
}

.light nav {
  background-color: #f8f9fa;
  color: var(--text-color-light);
}
.dark nav {
  background-color: #272727;
  color: var(--text-color-dark);
}

.navbar-brand {
  color: inherit !important;
}
.nav-link {
  color: inherit !important;
}
.active {
  color: #007bff !important;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.refresh {
  border: none;
  background-color: transparent;
  font-size: 16px;
  margin-left: 16px;
}

.refresh:focus {
  outline: none;
}

.larger-text {
  zoom: 55%;
}

.comments-wrapper {
  display: grid;
  grid-template: auto / auto;
  row-gap: 10px;
}

.comment {
  background-color: #28a745;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.comment-date {
  font-size: 12px;
}

.comment-text {
  margin-top: 10px;
  margin-bottom: 10px;
}

.comment-user {
  font-size: 12px;
  font-weight: bold;
}

.comment-new textarea {
  width: 100%;
  resize: none;
}

.comment-button {
  text-align: right;
}

.red {
  color: red;
}

.search-box {
  display: grid;
  grid-template: auto / auto 30px;
  align-items: center;
  column-gap: 10px;
}

.dashboard-data-table {
  text-align: right;
  margin: auto;
}

.dashboard-data-table > tbody {
  line-height: 22px;
}

.dashboard-data-column-country {
  padding-left: 10px;
  transform: scale(1);
  transform-origin: right;
  text-shadow: none;
  transition: all 0.5s ease-in-out;
}

.dashboard-data-column-animate {
  text-shadow:
    -0.3px -0.3px 0 #000,
    0.3px -0.3px 0 #000,
    -0.3px 0.3px 0 #000,
    0.3px 0.3px 0 #000;
  transform: scale(1.2);
  transform-origin: right;
  transition: all 0.5s ease-in-out;
}

.dashboard-data-column-name {
  text-align: left;
}

.arvato-recurring {
  background-color: rgba(255, 111, 111, 0.5) !important;
}

.action-button {
  margin-bottom: 16px;
}

.timeline {
  display: inline-block;
  background: rgba(111, 111, 111, 0.5);
  cursor: pointer;
  border-radius: 50%;
  padding: 6px;
  font-size: 16px;
}

.timeline:hover {
  background: rgba(111, 111, 111, 0.7);
}

.timeline-content {
  position: relative;
  overflow-y: scroll;
  max-height: 80vh;
}

.timeline-close {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 10px;
  z-index: 1000;
}

.timeline-content .row {
  margin: 0 auto;
}

.timeline-content > div {
  padding: 8px 16px;
  margin: 0;
}

.timeline-content > div:nth-child(odd) {
  background: rgba(0, 0, 0, 0.05);
}

.theme-toggle-btn {
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
}

.events-expand {
  margin-bottom: 1rem;
}

.mb-3 {
  margin: 0px !important;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.bg-gray {
  background-color: #6c757d !important;
  color: white !important;
}
.bg-red {
  background-color: #dc3545 !important;
  color: white !important;
}
.bg-blue {
  background-color: #007bff !important;
  color: white !important;
}
.bg-yellow {
  background-color: #ffc107 !important;
  color: white !important;
  border: 1px solid #ffc107 !important;
}
.bg-orange {
  background-color: #ff591e !important;
  color: white !important;
}
.page-link {
  color: #007bff !important;
  background-color: white !important;
}
.trash-icon {
  color: #dc3545 !important;
}
.customer-btn-row {
  display: flex;
  gap: 1rem;
}
.top-form-row {
  margin-top: 1rem; 
}

/* Person ID field styling */
.form-group {
  position: relative;
}

.form-group .btn-secondary.mt-1 {
  margin-left: 0;
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
}

/* Disabled input styling */
.form-control:disabled {
  background-color: #e9ecef !important;
  cursor: not-allowed;
  opacity: 0.7;
  color: #6c757d !important;
}

.dark .form-control:disabled {
  background-color: #495057 !important;
  color: #adb5bd !important;
}

.container-fluid {
  padding: 0px !important;
}

@media (max-width: 768px) {
  .navbar-brand {
    font-size: 1rem !important;
  }

  /* Mobile-specific table styles */
  .MuiTableCell-root {
    padding: 12px 8px !important;
  }

  .MuiTableCell-root:first-of-type {
    min-width: 120px;
  }

  .MuiTableCell-root:not(:first-of-type) {
    min-width: 60px;
  }
}
