body {
  width: 100%;
}

.dash-container {
  margin: 0px 80px 0px 80px;
  display: grid;
  grid-template: auto / repeat(20, 1fr);
  column-gap: 15px;
}

.fullrow {
  grid-column: 1/-1;
  width: 100%;
}

.left {
  grid-column: 1/8;
}

.center {
  grid-column: 8/14;
}

.right {
  grid-column: 14/-1;
}

.strong {
  font-weight: bold;
}

.split {
  display: grid;
  grid-template: 1fr 1fr / auto;
}

.justcenter {
  justify-self: center;
}

.justcenteritems {
  justify-items: center;
}

.std-table {
  width: 100%;
  margin: 0px auto;
  border-spacing: 0px;
}

.std-table th {
  font-size: 24px;
}

.std-table td {
  font-size: 20px;
  padding: 0px;
}

.grid {
  display: grid;
}

.halfgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.largetext {
  font-size: 124px;
}

.semitext {
  font-size: 18px;
  color: white;
}

.smalltext {
  font-size: 32px;
}

.lefthalf {
  grid-row: 1;
  grid-column: 1;
}

.righthalf {
  grid-row: 1;
  grid-column: 2;
}

.textcenter {
  text-align: center;
}

.moveup {
  margin-top: -130px;
}

.topspacer {
  margin-top: 70px;
}

.zprio {
  z-index: 1000;
}

.center-table {
  width: 100%;
  margin: 0px auto;
  border-spacing: 0px;
}

.center-table td {
  padding: 0px;
}

.fivelayout {
  display: grid;
  grid-template: auto / 2fr 2fr 1fr 2fr 2fr;
  align-items: center;
}
